.block-team {
    --padding-v: var(--padding-m);
    --padding-h: var(--padding-m);

    @media (max-width: $smartphone) {
        --padding-v: var(--padding-xs);
        --padding-h: var(--padding-xs);
    }

    .palette-primary & {
        --primary: var(--red);
        --secondary: var(--deepBlue);
    }

    .palette-black & {
        --primary: var(--deepBlue);
        --secondary: var(--red);
    }
}

.block-team {
    color: var(--gray);
    padding: var(--padding-v) var(--padding-h);

    &__item {
        border-radius: var(--border-radius-s);
    }

    &__header {
        > * {
            &:first-child {
                background: var(--primary);
                margin: 0 0 var(--padding-xs);
                padding: var(--padding-xs) var(--padding-s);

                svg {
                    height: 100%;
                    width: 100%;
                }
            }

            &:last-child {
                background: var(--secondary);
                font-size: var(--font-size-sans-medium-base);
                padding: var(--padding-s);

                @include font-sans2-regular();
            }
        }
    }

    &__people {
        background: var(--green);
        margin-top: var(--margin-xxs);
        padding: var(--padding-xs);

        .person:not(:last-child) { margin-bottom: var(--margin-s); }
    }

    @media (min-width: $smartphone) {
        &__header {
            display: flex;

            > *:first-child {
                margin: 0;
                margin-right: var(--margin-xs);
                padding: var(--padding-s) var(--padding-m);
                width: calc(43% - (var(--margin-xs) / 2));
            }

            > *:last-child {
                font-size: var(--font-size-sans2-large);
                padding: var(--padding-xl);
                width: calc(67% - (var(--margin-xs) / 2));
            }
        }

        &__item {
            border-radius: var(--border-radius-l);
            padding: var(--padding-l);
        }

        &__people { margin-top: var(--margin-xs); }
    }

    @media (max-width: $smartphone) {
        .person:not(:last-child) { margin-bottom: var(--padding-l); }
    }
}
