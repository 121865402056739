:root {
  --white: #fff;
  --black: #000;
  --gray: #ddd;
  --darkGray: #666;
  --red: #F73631;
  --lightBlue: #0092FD;
  --fluorBlue: #00FFFF;
  --deepBlue: #2626f9;
  --green: #008C45;
  --greenLight: #22AE73;
  --yellow: #DDFF1D;
  --ocher: #DCB40E;

  --assertive: #ff00ff;
  --focus: #ff00ff;
  --color-error: #ff0000;
  --color-sucess: #008C45;
}

.palette-primary {
    --primary: var(--black);
    --secondary: var(--red);
    --bgcolor: var(--gray);
}

.palette-black {
    --primary: var(--white);
    --secondary: var(--deepBlue);
    --bgcolor: var(--black);
}
