.default-form {
    --color: currentColor;
    --color-error: var(--red-bdcc);
    --font-size: var(--font-size-sans2-mid);
    --font-size-submit: 1.5em;

    color: var(--color);

    @media (max-width: $smartphone) {
        --font-size: var(--font-size-sans2-base);
        --font-size-submit: var(--font-size-sans-medium-base);
    }

    .--error {
        --color: #ff0000;
    }
}

.default-form {
    [type="submit"] {
        background: transparent;
        font-size: var(--font-size-submit);
        padding: 0;
        text-decoration: underline;

        @include font-sans-medium();
    }
}

.default-form__textarea,
.default-form__textfield {
    font-size: var(--font-size);
    position: relative;

    @include font-sans2-regular();

    &.error,
    &.__error {
        color: var(--red);

        &:after {
            @include pseudo-element-absolute();

            content: '!';
            bottom: .1em;
            color: currentColor;
            right: 0;
        }
    }
}

.default-form__textfield {
    align-items: flex-end;
    display: flex;
    justify-content: space-between;
    margin: 0 0 var(--margin-xs);

    @media (max-width: $smartphone) {
        margin: 0 0 var(--padding-s);
    }

    label {
        margin-right: em(12);
        text-align: left;
        width: em(60);
    }

    input {
        background: transparent;
        border: 0;
        border-bottom: 1px solid var(--color);
        flex-grow: 1;
        height: var(--font-size);
        line-height: var(--font-size);
    }

    &.error,
    &.__error {
        label { color: var(--white); }
    }
}

.default-form__textarea {
    margin: 0 0 var(--margin-xs);

    @media (max-width: $smartphone) {
        margin-top: var(--padding-l);
    }

    label {
        display: block;
        margin-bottom: rem(10);
        text-align: left;
    }

    textarea {
        background: transparent;
        border: 1px solid currentColor;
        min-height: em(100);
        padding: var(--padding-xxs);
        width: 100%;
    }

    &.error,
    &.__error {
        &:after {
            bottom: auto;
            right: var(--padding-xxs);
            top: calc(var(--font-size) * 1.75);
        }
    }
}

.default-form__footer {
    display: flex;

    @media (min-width: $smartphone) {
        justify-content: space-between;
    }

    @media (max-width: $smartphone) {
        flex-direction: column;

        .default-form__submit {
            align-self: flex-end;
            margin-top: var(--margin-s);
        }
    }
}

.default-form__checkbox {
    --size: 20px;
    --size-check: 16px;
    --font-size: .8em;

    color: var(--white);
    font-size: var(--font-size);
    height: var(--size);
    position: relative;

    @include font-sans2-regular();

    &:before {
        @include pseudo-element-absolute();

        margin: 0;
        width: var(--size);
        height: var(--size);
        border: 1px solid var(--color);
        background: transparent;
    }

    &.error { --color: var(--red); }

    label {
        align-items: center;
        cursor: pointer;
        display: flex;
        height: var(--size);
        padding-left: calc(var(--size) + var(--padding-xs));
        position: relative;

        a {
            color: currentColor;
            display: inline-block;
            font-weight: 500;
            margin-left: rem(8);
            text-decoration: underline;
        }

        &:after {
            @include pseudo-element-absolute();

            background: var(--color);
            border: 1px solid var(--color);
            height: var(--size-check);
            margin: 0;
            left: calc((var(--size) - var(--size-check)) * .5);
            opacity: 0;
            top: calc((var(--size) - var(--size-check)) * .5);
            transform: scale3d(0,0,1);
            transition: opacity .2s var(--ease-out-quad) .1s, transform .4s var(--ease-in-quad);
            width: var(--size-check);
        }

        &:hover {
            &:after {
                opacity: 1;
                transform: scale3d(.5,.5,1);
                transition: opacity .2s var(--ease-out-quad), transform .6s var(--ease-out-expo);
            }
        }
    }

    input[type=radio],
    input[type=checkbox] {
        position: absolute;
        visibility: hidden;

        &:checked + label:after {
            opacity: 1;
            transform: scale3d(1,1,1);
            transition: opacity .2s var(--ease-out-quad), transform .6s var(--ease-out-expo);
        }
    }
}