.mode-toggle {
    --bottom-pos: var(--marquee-height);
    --right-pos: var(--padding-m);

    .palette-primary & {
        --bg: var(--black);
        --color: var(--gray);;
    }

    .palette-black & {
        --bg: var(--gray);
        --color: var(--black);
    }
}

.mode-toggle {
    background: var(--bg);
    border-radius: var(--border-radius-s);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    bottom: var(--bottom-pos);
    color: var(--color);
    display: flex;
    font-size: var(--font-size-sans-bold-mid);
    justify-content: space-between;
    min-width: rem(265);
    padding: rem(8) rem(13) rem(17);
    position: fixed;
    text-decoration: none;
    text-transform: uppercase;
    right: var(--right-pos);
    z-index: 10;

    @include font-sans-black();

    @media (max-width: $smartphone) {
        display: none;
    }
}

.toggle {
    .palette-primary & {
        --toggle: var(--red);
    }

    .palette-black & {
        --toggle: var(--green);
    }

    background: var(--toggle);
    border-radius: var(--border-radius-s);
    display: block;
    height: rem(25);
    margin-right: var(--margin-xs);
    position: relative;
    width: rem(64);

    &::before {
        background: var(--black);
        border-radius: 50%;
        content: '';
        height: rem(18);
        position: absolute;
        left:rem(3);
        top:rem(3);
        width: rem(18);
    }

    .palette-black &::before {
        left: auto;
        right: rem(3);
    }

    @media (max-width: $smartphone) {
        height: rem(14);
        width: rem(34);

        &::before {
            height: rem(10);
            left:rem(2);
            top:rem(2);
            width: rem(10);
        }

        .palette-black &::before {
            left: auto;
            right: rem(2);
        }
    }
}
