@import "https://unpkg.com/swiper/swiper-bundle.min.css";

@import "utils/breakpoints";

@import "variables/colors";
@import "variables/paths";
@import "variables/fonts";
@import "variables/z-index";
@import "variables/ease";
@import "variables/sizes";

@import "utils/normalize";
@import "utils/normalize_cuchillo";
@import "utils/utils";
@import "utils/marquee";
@import "utils/mask";
@import "utils/scroll";
@import "utils/links";
@import "utils/cursor";
@import "utils/acordion";

@import "layout/header";
@import "layout/footer";
@import "layout/interface";
// @import "layout/scrollbar";

@import "links/link-arrow";

@import "blocks/block-default";
@import "blocks/block-courses";
@import "blocks/block-channels";
@import "blocks/block-contact";
@import "blocks/block-destiny";
@import "blocks/block-game";
@import "blocks/block-landing";
@import "blocks/block-kpi";
@import "blocks/block-news";
@import "blocks/block-perfect-match";
@import "blocks/block-team";
@import "blocks/block-text";
@import "blocks/block-wysiwyg";
@import "blocks/block-spacer";
@import "blocks/block-title";
@import "blocks/block-faqs";
@import "blocks/widget-form";
@import "blocks/widget-locations";
@import "blocks/block-full-gallery";
@import "blocks/block-gallery";

@import "components/game";

@import "form/default";

@import "modules/course";
@import "modules/course-card";
@import "modules/mode-toggle";
@import "modules/news";
@import "modules/person";
@import "modules/box";

@import "pages/default";

@import "pages/course";
@import "pages/news";
@import "pages/news-detail";

@import "windows/pop-up";
@import "windows/cookies";
@import "windows/video-stories";
@import "windows/message";
@import "windows/sidemenu";
@import "windows/modal-gallery";

@import "general";
