.pop-up {
    --bg: var(--yellow);
    --shadow: var(--deepBlue);
    --text-color: var(--white);
    --width: 75vw;
    --btnSize: #{(55px / 16px) * 1rem};
    
    @media (max-width: $smartphone) {
        --width: 90vw;
        --btnSize: #{(35px / 16px) * 1rem};
    }
}

.pop-up {
    @include z-index($z-index-windows);

    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100vw;
    
    .overlay {
        @include z-index($z-index-bg);
        
        background-color: var(--bg);
        bottom: 0;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        opacity: .6;
    }

    .content {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }
    
    .wrapper {
        display: block;
        left: 50%;
        position: relative;
        top: 50%;
        transform: translate3d(-50%, -50%, 0);
        width: var(--width);
        max-width: rem(850);
        
        .back {
            background: var(--shadow);
            border-radius: var(--border-radius-s);
            content: '';
            height: 100%;
            outline: rem(3) solid var(--shadow);
            position: absolute;
            left: rem(15);
            top: rem(15);
            width: 100%;
            z-index: 0;
        }

        .front {
            @include z-index($z-index-3D);
            @include basic-a();
            background-color: var(--white);
            outline: rem(3) solid var(--shadow);
            border-radius: var(--border-radius-s);
            display: block;
            overflow: hidden;
            position: relative;
        }
    }

    button {
        @include z-index($z-index-wrap);
        background: transparent;
        height: var(--btnSize);
        padding: 0;
        position: absolute;
        right: var(--padding-xxs);
        top: var(--padding-xxs);
        width: var(--btnSize);

        svg {
            height: 100%;
            pointer-events: none;
            width: 100%;
        }
    }
}