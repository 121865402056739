.block-news {
    --font-size: var(--font-size-sans-medium-mid);
    --color: var(--gray);
    --bg: var(--primary);
    --padding-v: 0;
    --padding-h: var(--padding-xxs);

    .palette-primary & {
        --primary: var(--deepBlue);
        --secondary: var(--red);
    }

    .palette-black & {
        --primary: var(--red);
        --secondary: var(--deepBlue);
    }
}

.block-news {
    align-items: center;
    background: var(--bg);
    color: var(--color);
    display: flex;
    font-size: var(--font-size);
    height: var(--marquee-height);
    justify-content: flex-start;
    padding: var(--padding-v) 0 var(--padding-v) var(--padding-h);
    z-index: 10;
    width: 100vw;

    @include font-sans-medium();

    &__logo {
        margin: 0;
        margin-right: var(--padding-xxs);
        min-width: rem(315);

        svg {
            height: rem(45);
            width: rem(315);

            path { fill: var(--secondary); }
            .palette-primary & .path-change { fill: var(--yellow); }
        }
    }

    &__carousel {
        flex-grow: 1;
        overflow: hidden;
    }

    @media (max-width: $smartphone) {
        display: none;
    }
}
