@mixin box() {
    border: rem(7) solid var(--border);
    border-radius: var(--border-radius-m);

    @media (max-width: $smartphone) {
        border-width: rem(3);
        border-radius: var(--border-radius-xs);
    }
}

.game {
    --thumb-height: #{(85 / 16) * 1rem};
    --thumb-width: #{(48 / 16) * 1rem};
    --thumb-radius: #{(9 / 16) * 1rem};
    --track-height: #{(25 / 16) * 1rem};
    --font-size-game: var(--font-size-sans-medium-mid);

    .palette-primary & {
        --crossfader-color: var(--deepBlue);
        --thumb-color: var(--green);
    }

    .palette-black & {
        --crossfader-color: var(--red);
        --thumb-color: var(--yellow);
    }

    @media (max-width: $tabletPortrait) {
        --track-height: #{(40 / 16) * 1rem};
    }

    @media (max-width: $smartphone) {
        --thumb-height: #{(36 / 16) * 1rem};
        --thumb-width: #{(20 / 16) * 1rem};
        --thumb-radius: #{(2 / 16) * 1rem};
        --track-height: #{(12 / 16) * 1rem};
    }
}

.game {
    display: grid;
    font-size: var(--font-size-game);
    grid-template-areas:
      "words1      words2      words3      words4"
      "words1      words2      words3      words4"
      "words1      words2      words3      words4"
      "words1      words2      words3      words4"
      "sounds      sounds      sounds      sounds"
      "buttons     buttons     buttons     buttons"
      "crossfader  crossfader  crossfader  crossfader";
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: var(--margin-xxxs);
    grid-template-rows: auto;

    @include font-sans-medium();

    &__words,
    &__sounds {
        display: grid;
        grid-gap: var(--margin-xxxs);
    }

    &__words {
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: repeat(4, 1fr);

        &--1 { grid-area: words1; }
        &--2 { grid-area: words2; }
        &--3 { grid-area: words3; }
        &--4 { grid-area: words4; }
    }

    &__sounds {
        grid-area: sounds;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(1, 1fr);
    }

    &__btns {
        display: grid;
        grid-area: buttons;
        grid-gap: var(--margin-xxs);
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(1, 1fr);
    }

    &__crossfader {
        background: var(--primary);
        height: rem(96);
        grid-area: crossfader;
        padding: rem(11) var(--padding-s);
        position: relative;
        width: 100%;

        @include box();

        .path-change { stroke: var(--thumb-color); }

        .crossfader {
            -webkit-appearance: none;
            appearance: none;
            background: transparent;
            border: 3px solid var(--crossfader-color);
            border-radius: 12px;
            height: var(--track-height);
            margin-top: rem(22);
            position: relative;
            width: 100%;
            z-index: 1;

            @mixin thumb () {
                background: var(--thumb-color);
                border-radius: var(--thumb-radius);
                box-shadow: none;
                cursor: pointer;
                height: var(--thumb-height);
                width: var(--thumb-width);
            }

            &::-webkit-slider-thumb {
                -webkit-appearance: none;
                appearance: none;

                @include thumb();
            }

            &::-moz-range-thumb {
                @include thumb();
            }
        }

        .plus,
        .minus,
        .slower,
        .faster {
            position: absolute;
            z-index: 1;

            svg {
                height: 100%;
                width: 100%;
            }
        }

        .plus,
        .minus {
            height: rem(13);
            top: rem(32);
            width: rem(13);
        }

        .plus { right: rem(5); }
        .minus { left: rem(5); }

        .slower {
            top: rem(52);
            left: rem(-12);
            width: rem(124);
        }

        .faster {
            top: rem(62);
            right: 0;
            width: rem(135);
        }
    }

    &__steps {
        align-items: flex-start;
        display: flex;
        justify-content: space-between;
        margin-top: var(--margin-xxs);

        .indicator {
            background: var(--crossfader-color);
            border-radius: var(--border-radius-s);
            width: rem(3);

            &--short { height: rem(4.5); }
            &--long { height: rem(9); }
        }

        @media (min-width: $desktop) {
            padding: 0 rem(25);
        }
    }

    .word,
    .sound {
        align-items: flex-end;
        background: transparent;
        display: flex;
        grid-column: span 1;
        grid-row: span 1;
        justify-content: flex-end;
        padding: var(--padding-xxxs);
        text-align: right;

        @include box();
    }

    .word {
        --bg: transparent;

        background: var(--bg);
        color: var(--color);

        &:hover {
            --bg: var(--green);
            --color: var(--black);
        }

        @include isTouch() {
            &:hover {
                --bg: transparent;
                --color: var(--primary);
            }
        }

        &.active {
            --bg: var(--red);
            --color: var(--black);

            transition: background .2s linear;

            &.flash {
                --bg: var(--white);
            }
        }
    }

    .sound {
        --bg: transparent;
        --border: var(--secondary);
        --color: var(--secondary);

        background: var(--bg);
        color: var(--color);

        @include isTouch() {
            &:hover {
                --bg: transparent;
                --color: var(--secondary);
            }
        }

        &:hover {
            --bg: var(--yellow);
            --color: var(--black);
        }

        &.active {
            --bg: var(--secondary);
            --color: var(--black);

            transition: background .2s linear;

            &.flash {
                --bg: var(--white);
            }
        }
    }

    .button-card {
        background: transparent;
        height: 100%;
        padding: 0;

        span,
        svg {
            height: 100%;
            width: 100%;
        }

        .play { display: block; }
        .stop { display: none; }

        &--play {
            &.active {
              .play { display: none; }
              .stop { display: block; }
            }
        }

        &--random {
            &:hover {
                .play { display: none; }
                .stop { display: block; }
            }

            @include isTouch() {
                &:hover {
                    .play { display: block; }
                    .stop { display: none; }
                }
            }
        }
    }

    @media (max-width: $smartphone) {
        .word,
        .sound {
            height: rem(71);
        }

        &__btns .desktop { display: none; }
    }

    @media (min-width: $smartphone) {
        grid-template-areas:
          "words1      words2      words3      words4      sounds     buttons"
          "words1      words2      words3      words4      sounds     buttons"
          "words1      words2      words3      words4      sounds     buttons"
          "words1      words2      words3      words4      sounds     buttons"
          "crossfader crossfader crossfader crossfader crossfader crossfader";
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
        grid-gap: var(--margin-xs);

        .word,
        .sound {
            padding: var(--padding-xxs) var(--padding-xs);
        }

        &__words,
        &__sounds,
        &__btns {
            display: grid;
            grid-gap: var(--margin-xs);
        }

        &__steps {
            margin-top: var(--margin-xxs);
        }

        &__sounds {
            grid-template-columns: repeat(1, 1fr);
            grid-template-rows: repeat(4, 1fr);
        }

        &__btns {
            grid-template-columns: repeat(1, 1fr);
            grid-template-rows: repeat(2, 1fr);

            .mobile { display: none; }
        }

        &__crossfader {
            height: rem(173);
            padding: rem(11) var(--padding-xxxl);

            .plus,
            .minus {
                height: rem(31);
                top: rem(28);
                width: rem(30);
            }

            .plus { right: rem(28); }
            .minus { left: rem(28); }

            .crossfader {
              border-width: 5px;
              border-radius: 12px;
              margin-top: rem(22);
            }

            .slower {
                top: rem(75);
                left: rem(-30);
                width: rem(306);
            }

            .faster {
                top: rem(95);
                right: 0;
                width: rem(333);
            }
        }

        &__steps {
            padding: 0 rem(20);
            margin-top: var(--margin-xs);

            .indicator {
                width: rem(7);

                &--short { height: rem(15); }
                &--long { height: rem(25); }
            }
        }
    }
}