@include keyframes('flickr') {
    0% {
      opacity: 0;
    }

    50% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
}

.block-destiny {
    --font-size-h2: var(--font-size-serif-extrabold-mid);
    --font-size: var(--font-size-sans-black-xxxlarge);
    --color: var(--red);
    --margin-v: var(--margin-m);
    --padding-bottom: var(--padding-l);
    --padding-top: var(--padding-l);
    --padding-h: var(--padding-l);

    @media (max-width: $smartphone) {
        --margin-v: var(--margin-s);
        --padding-bottom: var(--padding-xs);
        --padding-top: var(--padding-xs);
        --padding-h: var(--padding-xs);
    }
}

.block-destiny {
    color: var(--color);
    font-size: var(--font-size);
    margin: 0 auto var(--margin-v);
    padding: calc(var(--padding-top) + var(--margin-v)) var(--padding-bottom);

    @include font-sans-black();

    &__content {
        div:first-child {
            padding-left: var(--padding-s);
            position: relative;

            &::before {
                animation: flickr 1s infinite;
                background-color: var(--yellow);
                border-radius: 50%;
                content: '';
                height: rem(16);
                left: 0;
                position: absolute;
                top: rem(8);
                width: rem(16);
            }
        }

        div.image-mobile,
        div.image-desktop {
            position: relative;
        }
    }

    h2 {
        color: var(--green);
        font-size: var(--font-size-h2);
        margin: 0 0 var(--margin-xs);
        padding-bottom: var(--margin-xs);
        position: relative;

        @include font-serif-extrabold();

        hr {
            background-color: currentColor;
            border-radius: var(--border-radius-s);
            border: 0;
            bottom: 0;
            height: rem(1.8);
            left: 0;
            margin: 0;
            outline: none;
            position: absolute;
            width: 100%;
        }

        span {
            display: block;
            width: 52%;
        }
    }

    p {
        margin: 0;

        span {
            color: var(--primary);
            text-transform: uppercase;
        }
    }

    a img {
        height: 100%;
        width: 100%;
    }

    @media (max-width: $smartphone) {
        .image-desktop { display: none; }

        .image-mobile {
            text-align: center;

            a {
                display: inline-block;
            }

           img {
                height: rem(370);
                width: auto;
            }
        }

        &__content div:first-child {
            margin-bottom: var(--margin-s);
        }
    }

    @media (min-width: $smartphone) {
        .image-mobile { display: none; }

        h2 {
            margin: 0 0 var(--margin-s);
            padding-bottom: var(--margin-s);

            hr {
                height: rem(5);
            }

            span {
                width: 56%;
            }
        }

        &__content {
            display: flex;

            > div { width: 50%; }

            div:first-child {
                padding-left: var(--padding-l);

                &::before {
                    height: rem(35);
                    top: rem(32);
                    width: rem(35);
                }
            }

            div.image-desktop {
                position: relative;

                a {
                    left: 50%;
                    position: absolute;
                    top: 50%;
                    transform: translate3d(-50%, -50%, 0);
                }

                .block-destiny--offline & {
                    a {
                        height: rem(1020);
                        width: rem(620);
                    }
                }

                .block-destiny--online & {
                    a {
                        height: rem(990);
                        width: rem(760);
                    }
                }
            }
        }
    }

    @media (min-width: $desktop) {
        &__content {
            > div:first-child {
                padding-left: var(--padding-xl);

                &::before {
                    height: rem(40);
                    top: rem(23);
                    width: rem(40);
                }
            }
        }
    }
}
