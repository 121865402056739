.block-kpi {
    --font-size-h2: var(--font-size-sans-black-xxxxxlarge);
    --font-size-percent: var(--font-size-sans-medium-xlarge);
    --font-size: var(--font-size-sans2-xxlarge);
    --color: var(--primary);
    --margin-v: var(--margin-s);
    --margin-h: var(--padding-m);
    --padding-item-v: var(--padding-l);
    --padding-item-h: var(--padding-s);

    &__item {
        &--left { --bg: var(--yellow); }
        &--right { --bg: var(--green); }
    }

    @media (max-width: $smartphone) {
        --font-size-h2: var(--font-size-serif-bold-base);
        --font-size-percent: var(--font-size-sans-medium-xxlarge);

        --margin-v: var(--margin-xs);
        --margin-h: var(--padding-xs);
        --padding-item-v: var(--padding-xs);
        --padding-item-h: var(--padding-xs);
    }

    @media (max-width: $smartphoneMid) {
        --font-size-h2: var(--font-size-serif-bold-small);
        --font-size-percent: var(--font-size-sans-medium-l);
    }
}

.block-kpi {
    color: var(--color);
    font-size: var(--font-size);
    justify-content: space-between;
    padding: var(--margin-v) var(--margin-h);

    &__item {
        background: var(--bg);
        border-radius: var(--border-radius-s);
        padding: var(--padding-item-v) var(--padding-item-h);
        position: relative;
        text-align: center;
    }

    @include font-sans2-regular();

    h2 {
        color: var(--title-color);
        font-size: var(--font-size-h2);
        margin: 0;
        margin-bottom: rem(30);
        padding-bottom: rem(30);
        position: relative;

        @include font-sans-black();

        > div {
            align-items: flex-end;
            display: flex;
            justify-content: center;
        }

        .unit {
            font-size: var(--font-size-percent);

            @include font-sans-medium();
        }
    }

    hr {
        background-color: currentColor;
        border-radius: var(--border-radius-s);
        border: 0;
        bottom: 0;
        height: rem(5);
        left: 0;
        margin: 0;
        position: absolute;
        width: 100%;
    }

    p {
        margin: 0 auto;
        width: 68%;
    }

    @media (max-width: $smartphone) {
        h2 {
            align-items: flex-end;
            display: flex;
            justify-content: center;

            .unit { margin-bottom: rem(10); }
        }

        &__item:first-child { margin-bottom: calc(var(--margin-v) * 2); }
        &__item:last-child { transform: none !important; }
    }

    @media (min-width: $smartphone) {
        display: flex;

        &__item {
            border-radius: var(--border-radius-l);

            &:first-child { margin-right: var(--margin-v); }
            &:last-child { margin-left: var(--margin-v); }
        }
    }
}
