.news-detail {
    --margin-v: 0;
    --padding-v: var(--padding-xxxl);
    --padding-h: var(--padding-l);
    --max-width: #{(1320px / 16px) * 1rem};
    --font-size: var(--font-size-sans2-large);
    --font-size-title: var(--font-size-sans-medium-xlarge);

    @media (max-width: $smartphone) {
        --padding-v: var(--padding-s);
        --padding-h: var(--padding-s);
        --font-size: var(--font-size-sans2-base);
        --font-size-title: var(--font-size-sans-medium-base);
    }
}

.news-detail {
    font-size: var(--font-size);
    margin-top: var(--header-height);
    padding: var(--padding-v) var(--padding-h);

    @include font-sans2-regular();

    &__logo {
        background: var(--green);
        border-radius: var(--border-radius-s);
        margin: 0 auto;
        max-width: var(--max-width);
        padding: var(--padding-xs);
        text-align: center;

        svg {
            height: auto;
            width: 100%;
        }
    }

    &__content {
        margin: var(--margin-m) auto 0;
        max-width: var(--max-width);
    }

    &__title {
        position: relative;
        margin: 0 0 var(--padding-l);
        padding-bottom: var(--padding-xs);

        .title {
            color: var(--deepBlue);
            font-size: var(--font-size-title);
            max-width: rem(677);

            @include font-sans-medium();
        }

        .date {
            font-size: var(--font-size);

            @include font-sans2-regular();
        }

        .close {
            bottom: var(--padding-xs);
            position: absolute;
            right: 0;

            svg {
                height: rem(36);
                width: rem(36);
            }

            .icon { display: none; }
        }
    }

    hr {
        background-color: var(--red);
        border: 0;
        border-radius: var(--border-radius-xs);
        bottom: 0;
        content: '';
        height: rem(3);
        left: 0;
        margin: 0;
        position: absolute;
        width: 100%;
    }

    &__body {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .block-video {
            padding-bottom: 50%;
        }

        .block-video,
        .block-wysiwyg { width: 100%; }

        .block-image,
        .block-video {
            border-radius: var(--border-radius-s);
            overflow: hidden;
            position: relative;

            &:not(:last-child) { margin: 0 0 var(--padding-l); }

            .media-holder {
                height: 100%;
                left: 0;
                position: absolute;
                top: 0;

                * {
                    object-fit: cover;
                    object-position: center center;
                }
            }
        }

        .block-wysiwyg {
            margin-bottom: var(--padding-l);

            &:last-child { margin-bottom: 0; }
        }
    }

    &__date {
        display: none;
        justify-content: flex-end;
        position: relative;
        margin: var(--margin-s) 0 0;
        padding-bottom: var(--padding-v);
        padding-top: var(--padding-xs);

        hr {
            bottom: auto;
            top: 0;
        }
    }

    @media (max-width: $smartphone) {
        &__title .title {
            margin-bottom: var(--margin-s);
        }

        &__body {
            .block-image {
                padding-bottom: 100%;
                width: 100%;
            }
        }
    }

    @media (min-width: $smartphone) {
        &__content {
            margin: var(--margin-xxxxl) auto 0;
        }

        &__logo {
            border-radius: var(--border-radius-m);
            padding: var(--padding-s);

            hr {
                border-radius: var(--border-radius-s);
                height: rem(7);
            }
        }

        &__date { display: flex; }

        &__title {
            padding-bottom: var(--margin-s);

            .date { display: none; }
        }

        hr {
            border-radius: var(--border-radius-s);
            height: rem(7);
        }

        .close {
            bottom: auto;
            top: 0;

            svg {
                height: rem(48);
                width: rem(48);
            }

            .icon-full { display: none; }
            .icon { display: block; }
        }

        &__body {
            .block-image {
                padding-bottom: 50%;
                width: calc(50% - (1rem / 2));
            }

            .block-wysiwyg {
                margin-bottom: var(--padding-xxl);
            }

            .block-image,
            .block-video {

                &:not(:last-child) { margin: 0 0 var(--padding-xs); }
            }
        }
    }
}
