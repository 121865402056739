:root {
    --y-header: 0;

    --padding-xxxl: #{(90px / 16px) * 1rem};
    --padding-xxl: #{(72px / 16px) * 1rem};
    --padding-xl: #{(55px / 16px) * 1rem};
    --padding-l: #{(45px / 16px) * 1rem};
    --padding-m: #{(40px / 16px) * 1rem};
    --padding-s: #{(25px / 16px) * 1rem};
    --padding-xs: #{(15px / 16px) * 1rem};
    --padding-xxs: #{(8px / 16px) * 1rem};
    --padding-xxxs: #{(4px / 16px) * 1rem};

    --margin-xxxxl: #{(206px / 16px) * 1rem};
    --margin-xxxl: #{(160px / 16px) * 1rem};
    --margin-xxl: #{(145px / 16px) * 1rem};
    --margin-xl: #{(130px / 16px) * 1rem};
    --margin-l: #{(90px / 16px) * 1rem};
    --margin-m: #{(60px / 16px) * 1rem};
    --margin-s: #{(30px / 16px) * 1rem};
    --margin-xs: #{(15px / 16px) * 1rem};
    --margin-xxs: #{(7px / 16px) * 1rem};
    --margin-xxxs: #{(4px / 16px) * 1rem};

    --header-height: #{(90px / 16px) * 1rem};
    --marquee-height: #{(45px / 16px) * 1rem};
    --full-header: calc(var(--header-height) + var(--marquee-height));

    --border-radius-l: #{(45px / 16px) * 1rem};
    --border-radius-m: #{(25px / 16px) * 1rem};
    --border-radius-s: #{(15px / 16px) * 1rem};
    --border-radius-xs: #{(5px / 16px) * 1rem};

    @media (max-width: $smartphone) {
        --header-height: #{(64px / 16px) * 1rem};

        --full-header: var(--header-height);
    }
}
