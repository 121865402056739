.news-page {
    --margin-v: 0;
    --padding-v: var(--padding-m);
    --padding-h: var(--padding-m);
    --max-width: #{(1320px / 16px) * 1rem};

    @media (max-width: $smartphone) {
        --padding-v: var(--padding-xs);
        --padding-h: var(--padding-xs);
    }
}

.news-page {
    margin-top: var(--full-header);
    padding: var(--padding-v) var(--padding-h) 0;

    h1 {
        background: var(--green);
        border-radius: var(--border-radius-s);
        margin: 0 auto;
        max-width: var(--max-width);
        padding: var(--padding-xs);
        text-align: center;

        svg {
            height: auto;
            width: 100%;
        }
    }

    ul,
    li { list-style: none; }

    li {
        position: relative;

        hr {
            background-color: var(--red);
            border: 0;
            border-radius: var(--border-radius-xs);
            bottom: 0;
            content: '';
            height: rem(3);
            left: 0;
            margin: 0;
            position: absolute;
            width: 100%;
        }
    }

    &__content {
        margin: var(--margin-m) auto 0;
        max-width: var(--max-width);
        padding-bottom: var(--margin-xxxxl);

        li:not(:last-child) { margin-bottom: var(--margin-s); }
    }

    @media (min-width: $smartphone) {
        &__content {
            margin: var(--margin-xxxxl) auto 0;
        }

        h1 {
            border-radius: var(--border-radius-m);
            padding: var(--padding-s);

            hr {
                border-radius: var(--border-radius-s);
                height: rem(7);
            }
        }
    }
}
