.block-full-gallery {
    --cols: 4;
    --height: auto; 
    --width: 25%;
    --gap: var(--padding-xs);
    --radius: var(--border-radius-m);
    --padding-v: var(--padding-xs);
    --padding-h: var(--padding-m);
    
    @media (max-width: $smartphone) {
        --radius: var(--border-radius-xs);
        --padding-v: var(--padding-xs);
        --padding-h: var(--padding-xs);
        --cols: 3;
    }
}

.block-full-gallery {
    padding: var(--padding-v) var(--padding-h);

    &:first-child {
        padding-top: calc(var(--header-total) + var(--padding-v));
    }

    &__gallery {
        display: grid;
        grid-template-columns: repeat(var(--cols), 1fr);
        gap: var(--gap);
        padding: 0;
        margin: 0;
    }

    &__item {
        @include aspect-ratio(1, 1);
        border-radius: var(--radius);
        overflow: hidden;
        position: relative;
        cursor: pointer;

        * {
            pointer-events: none;
        }

        figure {
            position: absolute;
            top:0;
            left:0;
            padding-top: 100%;
            width: 100%;
            
            img {
                left: 0;
                top: 0;
                position: absolute;
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }

        @include isCursor() {
            figure {
                transition: transform .2s ease-out;
            }

            &:hover {
                figure {
                    transform: scale3d(1.1, 1.1, 1);
                }
            }
        }
    }

    // @media (min-width: $smartphone) {
    //     &__item {
    //         padding-top: 100%;
    //     }
    // }
}
