.news {
    --color: var(--primary);
    --font-size: var(--font-size-sans2-large);
    --font-size-title: var(--font-size-sans-medium-xlarge);

    @media (max-width: $smartphone) {
        --font-size: var(--font-size-sans2-base);
        --font-size-title: var(--font-size-sans-medium-base);
    }
}

.news {
    color: var(--color);
    font-size: var(--font-size);
    padding-bottom: var(--margin-xs);
    position: relative;
    text-decoration: none;
    width: 100%;

    @include font-sans2-regular();

    &__title {
        color: var(--deepBlue);
        font-size: var(--font-size-title);

        @include font-sans-medium();
    }


    .open {
        bottom: var(--margin-xs);
        height: rem(36);
        position: absolute;
        right: 0;
        width: rem(36);

        svg {
            height: 100%;
            width: 100%;
        }
    }

    @media (max-width: $smartphone) {
        display: block;

        &__title {
            margin-bottom: var(--margin-s);
        }
    }

    @media (min-width: $smartphone) {
        align-items: flex-end;
        display: flex;
        justify-content: space-between;
        padding-bottom: var(--margin-s);

        .open { display: none; }

        &__date {
            min-width: rem(310);
            text-align: right;
        }
    }
}
