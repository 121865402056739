@include keyframes('chevron') {
    0% {
        opacity: 0;
    }

    49% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 1;
    }
}

.block-text {
    --font-size-h2: var(--font-size-sans-black-xxxxlarge);
    --font-size: var(--font-size-sans2-xxlarge);
    --text-color: var(--black);
    --title-color: var(--black);
    --title-width: 100%;
    --margin-bottom: var(--padding-m);
    --margin-top: var(--padding-m);
    --margin-h: var(--padding-m);
    --padding-v: var(--padding-m);
    --padding-h: var(--padding-m);
    --contact-height-s: #{rem(96)};
    --contact-height-m: #{rem(90)};
    --contact-height: var(--contact-height-s);

    &.--default {
        --bg: var(--green);
        --box-bg: var(--yellow);
        --box-color: var(--black);
        --shadow-bg: var(--red);
    }

    &.--blue {
        --bg: var(--deepBlue);
        --text-color: var(--white);
    }

    &.--link {
        --bg: var(--deepBlue);
        --text-color: var(--white);
        --title-color: var(--yellow);
    }

    &.--red {
        --bg: var(--red);
        --title-color: var(--yellow);
        --box-bg: var(--deepBlue);
        --shadow-bg: var(--green);
        --box-color: var(--gray);
    }

    &.--title-50 {
        --title-width: 50%;
    }

    &.--title-65 {
        --title-width: 65%;
    }

    &.--title-85 {
        --title-width: 85%;
    }

    @media (max-width: $smartphone) {
        --font-size-h2: var(--font-size-sans-black-xxxlarge);
        --contact-height: var(--contact-height-m);
        --margin-bottom: var(--margin-s);
        --margin-top: var(--margin-s);
        --margin-h: var(--padding-xs);
        --padding-v: var(--padding-s);
        --padding-h: var(--padding-s);
    }

    @media (max-width: $smartphoneMid) {
        --font-size-h2: #{(30px / 16px) * 1rem};
        --padding-v: var(--padding-xs);
        --padding-h: var(--padding-xs);
    }

    // @media (min-width: $smartphone) {
    //     &.--title-50 {
    //         --margin-top: var(--margin-xl);
    //     }
    // }
}

.block-text {
    color: var(--text-color);
    padding: var(--margin-top) var(--margin-h) var(--margin-bottom);

    &__wrapper {
        background: var(--bg);
        border-radius: var(--border-radius-s);
        padding: var(--padding-v) var(--padding-h);
        position: relative;

        .block-text.--link & {
            padding-bottom: calc(var(--padding-xs) + var(--contact-height) + var(--padding-s));
        }
    }

    h2 {
        color: var(--title-color);
        font-size: var(--font-size-h2);
        margin: 0;

        @include font-sans-black();
    }

    .text {
        font-size: var(--font-size);
        margin: var(--margin-xxs) 0 0;

        @include font-sans2-regular();
    }

    &__quote,
    &__box {
        position: relative;
        transform: rotate(-5deg);

        @include font-serif-extrabold();

        .front,
        .back {
            border: rem(3) solid var(--shadow-bg);
            border-radius: var(--border-radius-xs);
        }

        .front {
            background: var(--box-bg);
            color: var(--box-color);
            padding: var(--padding-xs);
            position: relative;
            z-index: 1;
        }

        .back {
            background: var(--shadow-bg);
            height: 100%;
            position: absolute;
            right: rem(10);
            top: rem(10);
            width: 100%;
            z-index: 0;
        }
    }

    &__box {
        font-size: var(--font-size-serif-extrabold-mid);
    }

    &__quote {
        font-size: var(--font-size-serif-extrabold-base);

        .front {
            .quote {
                padding-bottom: var(--padding-xs);
                position: relative;
                margin-bottom: var(--margin-xs);

                hr {
                    background-color: var(--shadow-bg);
                    border-radius: var(--border-radius-s);
                    border: 0;
                    bottom: 0;
                    height: rem(3);
                    left: 0;
                    margin: 0;
                    position: absolute;
                    width: 100%;
                }
            }

            .author {
                font-size: var(--font-size-sans-bold-mid);
                text-transform: uppercase;

                @include font-sans-black();
            }
        }

        img {
            left: 10%;
            position: absolute;
            bottom: 55%;
            width: 85%;
        }
    }

    &__contact-link {
        align-items: center;
        background: var(--red);
        border-bottom-left-radius: var(--border-radius-s);
        border-bottom-right-radius: var(--border-radius-s);
        bottom: 0;
        color: var(--primary);
        display: flex;
        font-size: var(--font-size-sans-black-mid);
        height: var(--contact-height-s);
        justify-content: space-between;
        left: 0;
        padding: var(--padding-xs) var(--padding-xxs);
        position: absolute;
        right: 0;
        text-decoration: none;

        @include font-sans-black();

        > span:first-child { display: flex; }

        .chevron {
            $animationTime: 1.5s;
            $elements: 31;

            animation: chevron #{$animationTime} infinite;

            @for $i from 1 through $elements {
                &:nth-child(#{$i}) {
                    animation-delay: #{$i * $animationTime / $elements};
                }
            }
        }

        &:hover .chevron {
            animation-play-state: paused;
        }

        @include isTouch() {
            &:hover .chevron {
                animation-play-state: running;
            }
        }
    }

    &__image {
        height: rem(560);
        width: rem(518);

        span {
            left: 0;
            overflow: hidden;
            position: absolute;
            width: 100%;
        }

        .back,
        .front { bottom: 0; }

        .front { z-index: 2; }

        .mid {
            bottom: 12%;
            height: 0;
            max-height: 88%;
            z-index: 1;
        }

        .back { z-index: 0; }
    }

    @media (max-width: $smartphone) {
        &__box {
            margin: 50px auto 30px;
            width: 70%;

            .--title-50 &,
            .--title-85 & { width: 90%; }
        }

        &__wrapper {
            .block-text.--link & {
                padding-bottom: calc(var(--padding-xs) + var(--contact-height) + var(--padding-xxl));
            }
        }

        &__quote {
            margin: 200px auto 30px;
            width: 95%;

            img {
                transform: rotate(5deg) !important;
            }
        }

        &__quote > *,
        &__box > *,
        .back {
            transform: none !important;
        }

        &__image {
            height: rem(310);
            position: relative;
            margin: var(--margin-m) auto 0;
            width: rem(325);

            .mid {
                bottom: 14%;
            }
        }

        &__contact-link {
            flex-direction: column;
            text-align: center;

            .chevron {
                &:nth-child(-n + 3),
                &:nth-last-child(-n + 3) { display: none; }

                svg {
                    height: 24px;
                    width: 13px;
                }
            }
        }
    }

    @media (max-width: $smartphoneMid) {
        &__contact-link {
            .chevron {
                &:nth-child(-n + 5),
                &:nth-last-child(-n + 5) { display: none; }
            }
        }

        &__image {
            height: rem(232);
            position: relative;
            margin: var(--margin-m) auto 0;
            width: rem(243);

            .mid {
                bottom: 14%;
            }
        }
    }

    @media (min-width: $smartphone) {
        h2 {
            width: var(--title-width);
        }

        .text {
            margin: var(--margin-s) 0 0;
            width: 40%;
        }

        &__image {
            position: absolute;
            right: var(--padding-l);
            top: var(--padding-l);
        }

        &__wrapper {
            border-radius: var(--border-radius-l);
            min-height: rem(800);
        }

        &__quote,
        &__box {
            position: absolute;
            right: rem(45);

            .--title-50 & { width: 44%; }
            .--title-85 & { width: 34%; }

            .front { padding: var(--padding-l); }

            .front,
            .back {
                border: rem(5) solid var(--shadow-bg);
            }

            .block-text--padding & { bottom: rem(0); }

            .quote {
                padding-bottom: var(--padding-xl);
                margin-bottom: var(--margin-s);

                hr {
                    height: rem(5);
                }

                img {
                    left: 17%;
                    top: -100%;
                }
            }
        }

        &__box { bottom: rem(60); }
        &__quote { bottom: rem(45); }

        &__contact-link {
            border-radius: var(--border-radius-s);
            border-bottom-left-radius: var(--border-radius-l);
            border-bottom-right-radius: var(--border-radius-l);
            bottom: var(--padding-xs);
            font-size: var(--font-size-sans-black-xlarge);
            height: var(--contact-height);
            left: var(--padding-xs);
            padding: 0 var(--padding-l);
            width: calc(100% - var(--padding-xs) * 2);

            svg {
                height: rem(61);
                width: rem(29);
            }
        }
    }
}
