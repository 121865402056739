@use "sass:math";

.block-gallery {
    --height: 50vh;
    --width-slide: auto;
    --gap: var(--padding-m);
    --padding-v: var(--padding-m);
    --padding-h: var(--padding-m);
    --font-size-h2: var(--font-size-sans-black-xxxxlarge);
    --title-width: 85%;
    
    @media (max-width: $smartphone) {
        --padding-v: var(--padding-xs);
        --padding-h: var(--padding-xs);
        --height: 70vw;
        --title-width: 100%;
    }
}

.block-gallery {
    padding: var(--padding-v) var(--padding-h);

    h2 {
        color: var(--yellow);
        font-size: var(--font-size-h2);
        margin: 0;
        margin-bottom: var(--padding-l);
        max-width: var(--title-width);

        @include font-sans-black();
    }
    
    &__header {
        padding: 0 var(--padding-h);
    }
    
    &__wrapper {
        background: var(--deepBlue);
        border-radius: var(--border-radius-s);
        padding: var(--padding-v) 0;
        position: relative;
    }

    &__slider {
        position: relative;
        display: flex;
        flex-direction: column;
        user-select: none;
        cursor: grab;    
        padding: 0 var(--padding-h);

        &:active {
            cursor: grabbing;
        }

        > .holder {
            order: 1;
            position: relative;
            height: var(--height);
            display: flex;
            flex-wrap: nowrap;
            gap: var(--gap);
            overflow: hidden;
            // padding-left: calc(var(--padding-h) - var(--gap));
        }
    }

    &__item {
        position: relative;

        width: var(--width-slide);
        height: var(--height);
        
        // &:not(:last-child) {
        //     padding-right: var(--gap);
        // }

        svg {
            width: var(--width-slide);
            height: var(--height);
            display: block;
        }

        figure {
            border-radius: var(--border-radius-s);
            overflow: hidden;
        }
        
        img {
            width: var(--width-slide);
            height: var(--height);
            -webkit-user-drag: none;
            -khtml-user-drag: none;
            -moz-user-drag: none;
            -o-user-drag: none;
            user-drag: none;
        }
    }

    &__controls {
        display: flex;
        justify-content: center;
        gap: var(--padding-xs);
        margin-top: var(--padding-s);

        button {
            @include basic-a();
            @include font-sans-black();

            background-color: transparent;
            border: 0;
            color: var(--black);
            font-size: var(--font-size-sans-medium-base);
            padding: 0;
        }
    }

    &__footer {
        display: flex;
        justify-content: center;
        margin-top: var(--padding-xs);
        
        a {
            @include basic-a();
            @include font-sans-black();
            
            color: var(--red);
            background-color: var(--red);
            border: 2px solid var(--red);
            color: var(--black);
            font-size: var(--font-size-sans-medium-base);
            padding: var(--padding-xs) var(--padding-s);
            border-radius: var(--border-radius-s);
        }
        
        @include isCursor(){
            a:hover {
                background-color: var(--yellow);
                border: 2px solid var(--yellow);
            }
        }
    }

    @media (max-width: $smartphone) {
        h2 {
            margin-bottom: var(--padding-s);
        }

        .holder {
            -webkit-overflow-scrolling: touch;
            overflow: hidden;
            overflow-x: scroll;
            scroll-snap-type: x mandatory;

            &::-webkit-scrollbar {
                display: none;
            }
        }

        &__item {
            scroll-snap-align: start;
        }
    }
}
