.block-default {
    --font-size: var(--font-size-sans-bold-base);
    --color: var(--primary);
    --max-width: #{(1500px / 16px) * 1rem};
    --margin-v: 0;
    --padding-v: var(--padding-xxxl);
    --padding-h: var(--padding-l);
}

.block-default {
    color: var(--primary);

    @include font-sans-medium();
    font-size: var(--font-size);
    max-width: var(--max-width);
    margin: var(--margin-v) auto;
    padding: calc(var(--padding-v) + 1em) var(--padding-h);

    h1 {
        @include font-sans-black(1);
    }

    h2 {
        @include font-sans-black(1);
    }

    h3 {
        @include font-sans-black(1);
    }

    svg {
        max-width: 100px;
        height: auto;
    }

    p {
        i {
            @include font-sans-medium();
        }

        strong,
        b {
            @include font-sans-black();
        }
    }

    ul {
        margin: 2em 0;
        padding-left: 4em;
    }

    a {
        @include basic-a();
        @include font-sans-black();
        text-decoration: underline;
        color: inherit;
    }
}

@media (max-width: $smartphone) {
  .block-default {
      --padding-v: var(--padding-l);
      --padding-h: var(--padding-xs);
  }
}
