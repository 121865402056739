.block-landing {
    --font-size-h1: var(--font-size-sans-black-xxxxlarge);
    --font-size-h2: var(--font-size-serif-extrabold-large);
    --font-size-blockuote: var(--font-size-sans-black-xl);
    --font-size-p: var(--font-size-sans-medium-l);
    --font-size-pre: var(--font-size-sans-medium-mid);
    --padding-v-top: var(--padding-m);
    --padding-v-bottom: var(--padding-l);
    --padding-h: var(--padding-xs);
    --color: var(--primary);
    --border: var(--primary);
    --line-position: var(--padding-s);

    .palette-primary & {
        --primary: var(--red);
        --secondary: var(--deepBlue);
    }

    .palette-black & {
        --primary: var(--deepBlue);
        --secondary: var(--red);
    }

    @media (max-width: $smartphone) {
        --font-size-p: #{rem(22)};
    }

    @media (min-width: $smartphone) {
        --padding-v-top: var(--padding-m);
        --padding-v-bottom: var(--padding-l);
        --padding-h: var(--padding-m);
        --line-position: var(--padding-l);
    }
}

.block-landing {
    color: var(--color);
    padding: var(--padding-v-top) var(--padding-h) var(--padding-v-bottom);
    position: relative;
    text-align: center;

    h1,
    h2 {
        margin: 0;
        // margin-bottom: var(--margin-xs);
        padding: var(--padding-xs);

        @include box();
    }

    h1 {
        font-size: var(--font-size-h1);

        @include font-sans-black();
    }

    h2 {
        color: var(--green);
        font-size: var(--font-size-h2);
        text-transform: uppercase;

        @include font-serif-extrabold(1);
    }

    &__header {
        margin-bottom: var(--padding-m);
    }
    
    @media (max-width: $tabletPortrait) {
        h1,
        h2 {
            margin-bottom: var(--margin-xxs);
        }

        &__header {
            margin-bottom: var(--padding-s);
        }
    }

    @media (max-width: $smartphone) {
        padding: var(--padding-xs) var(--padding-h);

        .lines { display: none; }
    }

    > p {
        @include font-sans2-regular();
        font-size: var(--font-size-p);
        margin: 0;

        &:not(:last-child) {
            margin-bottom: var(--padding-m)
        }

        strong, b {
            @include font-sans-medium();
        }
    }

    pre {
        @include font-sans2-regular();
        font-size: var(--font-size-pre);

        strong, b {
            @include font-sans-medium();
        }
    }

    blockquote {
        @include font-serif-extrabold(1);
        font-size: var(--font-size-blockuote);
        text-transform: uppercase;
    }

}