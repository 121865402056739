.widget-locations {
    --font-size-rrss: var(--font-size-sans-black-xlarge);
    --font-size: var(--font-size-sans-medium-mid);
    --padding-v: var(--padding-m);
    --padding-h: var(--padding-m);
    --bg: var(--deepBlue);
    --font-size-h2: var(--font-size-sans-black-xxxxlarge);
    
    @media (max-width: $smartphone) {
        --padding-v: var(--padding-m);
        --padding-h: var(--padding-xs);
        --font-size: var(--font-size-sans2-base);
    }
}

.widget-locations {
    color: var(--color);
    padding: var(--padding-v) var(--padding-h);

    &__content {
        display: grid;
        font-size: var(--font-size);
        grid-template-areas:
          "item"
          "item"
          "item";
        grid-template-columns: 1fr;
        grid-gap: var(--margin-xs);
        grid-template-rows: auto;
    }

    h2 {
        color: var(--red);
        font-size: var(--font-size-h2);
        margin: 0;
        margin-bottom: var(--padding-m);

        @include font-sans-black();
    }

    .box {
        --border: var(--red);
    }

    @media (max-width: $smartphone) {
        --font-size-h2: var(--font-size-sans-black-xxxlarge);

        padding: var(--padding-xs);
        padding-top: 0;

        h2 {
            margin-bottom: var(--padding-xs);
        }
    }

    @media (max-width: $smartphoneMid) {
        --font-size-h2: #{(30px / 16px) * 1rem};
    }

    @media (min-width: $smartphone) {
        &__content {
            grid-template-areas:
            "item item"
            "item item"
            "item item";
            grid-template-columns: 1fr 1fr;
        }
    }

    @media (min-width: $tabletPortrait) {
        &__content {
            grid-template-areas:
            "item item item"
            "item item item";
            grid-template-columns: 1fr 1fr 1fr;
        }
    }
}
