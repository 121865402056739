.person {
    border-bottom: 1px solid currentColor;
    display: flex;
    font-size: var(--font-size-sans2-base);
    padding-bottom: var(--padding-xl);

    * { pointer-events: none; }

    @include font-sans2-regular();

    &__bio {
        *:first-child { margin-top: 0; }
        *:last-child { margin-bottom: 0; }
    }

    &__name {
        display: flex;
        font-size: var(--font-size-sans-black-base);

        @include font-sans-black();

        &::before {
            content: attr(data-number);
            color: var(--black);
            margin-right: var(--margin-xs);
        }
    }

    &__image {
        border-radius: var(--border-radius-xs);
        overflow: hidden;

        .media-holder {
            height: rem(416);

            img { object-fit: cover; }
        }
    }

    @media (max-width: $smartphone) {
        flex-direction: column;

        &:last-child { border: none; }

        &__country {
            order: 2;
            width: rem(65);
        }

        &__image { order: 3; }
        &__bio { order: 4; }

        &__country,
        &__image {
            margin-bottom: var(--margin-s);
        }

        &__name {
            flex-direction: column;
            margin-bottom: var(--margin-xs);
            order: 1;
        }
    }

    @media (min-width: $smartphone) {
        align-items: stretch;
        justify-content: space-between;
        padding-bottom: var(--margin-s);

        &__name {
            font-size: var(--font-size-sans-bold-large);
            margin-right: var(--margin-m);
            min-width: rem(720);
        }

        &__image {
            display: none;
        }

        &__bio {
            margin-right: var(--margin-xxxl);
        }
    }
}
