:root {
    --font-sans-black: "ABCGintoNord-Black", sans-serif;
    --font-sans-medium: "ABCGintoNord-Medium", sans-serif;
    --font-sans2-regular: "ProximaNova", sans-serif;
    --font-serif-extrabold: "Teodor", sans-serif;

    --font-size-sans-black-xxxxxxlarge: #{(300px / 16px) * 1rem};
    --font-size-sans-black-xxxxxlarge: #{(192px / 16px) * 1rem};
    --font-size-sans-black-xxxxlarge: #{(150px / 16px) * 1rem};
    --font-size-sans-black-xxxlarge: #{(97px / 16px) * 1rem};
    --font-size-sans-black-xxlarge: #{(73px / 16px) * 1rem};
    --font-size-sans-black-xlarge: #{(70px / 16px) * 1rem};
    --font-size-sans-black-xl: #{(64px / 16px) * 1rem};
    --font-size-sans-black-large: #{(50px / 16px) * 1rem};
    --font-size-sans-black-l: #{(35px / 16px) * 1rem};
    --font-size-sans-black-mid: #{(29px / 16px) * 1rem};
    --font-size-sans-black-base: #{(23px / 16px) * 1rem};
    --font-size-sans-black-base-sm: #{(21px / 16px) * 1rem};
    --font-size-sans-black-small: #{(17px / 16px) * 1rem};

    --font-size-sans-bold-large: #{(44px / 16px) * 1rem};
    --font-size-sans-bold-mid: #{(28px / 16px) * 1rem};
    --font-size-sans-bold-base: #{(24px / 16px) * 1rem};

    --font-size-sans-medium-xxlarge: #{(97px / 16px) * 1rem};
    --font-size-sans-medium-xlarge: #{(73px / 16px) * 1rem};
    --font-size-sans-medium-large: #{(65px / 16px) * 1rem};
    --font-size-sans-medium-l: #{(40px / 16px) * 1rem};
    --font-size-sans-medium-mid: #{(24px / 16px) * 1rem};
    --font-size-sans-medium-base: #{(23px / 16px) * 1rem};
    --font-size-sans-medium-small: #{(15px / 16px) * 1rem};

    --font-size-sans2-xxlarge: #{(44px / 16px) * 1rem};
    --font-size-sans2-xlarge: #{(39px / 16px) * 1rem};
    --font-size-sans2-large: #{(29px / 16px) * 1rem};
    --font-size-sans2-mid: #{(23px / 16px) * 1rem};
    --font-size-sans2-base: #{(21px / 16px) * 1rem};

    --font-size-serif-bold-base: #{(80px / 16px) * 1rem};
    --font-size-serif-bold-small: #{(70px / 16px) * 1rem};

    --font-size-serif-extrabold-large: #{(97px / 16px) * 1rem};
    --font-size-serif-extrabold-mid: #{(88px / 16px) * 1rem};
    --font-size-serif-extrabold-base: #{(70px / 16px) * 1rem};

    --line-height-sans: 1.04;
    --line-height-sans2: 1.04;
    --line-height-serif: 1.04;
    --letter-spacing: 0;

    font-size: 0.83vw; // 16 en la pantalla de 1920

    @media (max-width: $smartphone) {
        font-size: 16px;

        --font-size-sans-black-xxxxlarge: #{(50px / 16px) * 1rem};
        --font-size-sans-black-xxxlarge: #{(33px / 16px) * 1rem};
        --font-size-sans-black-xlarge: #{(35px / 16px) * 1rem};
        --font-size-sans-black-mid: #{(26px / 16px) * 1rem};

        --font-size-sans-bold-mid: #{(12px / 16px) * 1rem};

        --font-size-sans-medium-xxlarge: #{(42px / 16px) * 1rem};
        --font-size-sans-medium-mid: #{(10px / 16px) * 1rem};

        --font-size-serif-extrabold-large: #{(27px / 16px) * 1rem};
        --font-size-serif-extrabold-mid: #{(29px / 16px) * 1rem};
        --font-size-serif-extrabold-base: #{(29px / 16px) * 1rem};

        --font-size-sans2-xxlarge: #{(23px / 16px) * 1rem};
    }

    @media (max-width: $smartphoneMid) {
        font-size: 14px;
    }
}

@mixin font-sans-black($line-height: 0) {
    font-family: var(--font-sans-black);
    letter-spacing: -1px;

    @if $line-height > 0 { line-height: $line-height;  }
    @else { line-height: var(--line-height-sans);  }
}

@mixin font-sans-medium($line-height: 0) {
    -webkit-font-smoothing: subpixel-antialiased;
    font-family: var(--font-sans-medium);
    letter-spacing: -1px;

    @if $line-height > 0 { line-height: $line-height;  }
    @else { line-height: var(--line-height-sans);  }
}

@mixin font-sans2-regular($line-height: 0) {
    font-family: var(--font-sans2-regular);

    @if $line-height > 0 { line-height: $line-height;  }
    @else { line-height: var(--line-height-sans2);  }
}

@mixin font-serif-extrabold($line-height: 0) {
    font-family: var(--font-serif-extrabold);

    @if $line-height > 0 { line-height: $line-height;  }
    @else { line-height: var(--line-height-serif);  }
}
