.course-card {
    --font-size-h2: var(--font-size-sans-black-xl);
    --padding-v: var(--padding-m);
    --padding-h: var(--padding-s);
    --color: var(--white);
    --gap: var(--margin-s);

    &.--blue {
        --bg: var(--deepBlue);
    }

    &.--green {
        --bg: var(--green);
    }

    &.--red {
        --bg: var(--red);
    }

    @media (max-width: $smartphone) {
        --font-size-h2: var(--font-size-sans-black-small);
        --padding-v: var(--padding-xs);
        --padding-h: var(--padding-xs);
        --gap: var(--margin-xs);
    }

    @media (max-width: $smartphoneMid) {
        --font-size-h2: var(--font-size-sans-black-l);
    }
}

.course-card {
    background: var(--bg);
    border-radius: var(--border-radius-s);
    color: var(--color);
    display: block;
    margin-bottom: var(--gap);
    padding-bottom: 140%;
    position: relative;
    overflow: hidden;
    text-decoration: none;
    width: 100%;

    @include font-sans2-regular();

    &__front {
        bottom: 0;
        left: 0;
        padding: var(--padding-v) var(--padding-h);
        position: absolute;
        right: 0;
        top: 0;

        .title {
            font-size: var(--font-size-h2);
            margin: 0;

            @include font-sans-black();
        }

        .media-holder {
            bottom: var(--padding-v);
            height: rem(416);
            left: 50%;
            padding-top: 0;
            position: absolute;
            transform: translate3d(-50%, 0, 0);
            width: rem(316);
        }
    }

    @media (min-width: $smartphoneMid) {
        padding-bottom: 75%;
        width: calc(50% - (var(--gap) / 2));

        &:nth-child(even) {
            margin-left: var(--gap);
        }

        .media-holder {
            height: rem(208);
            width: rem(158);
        }
    }

    @media (min-width: $smartphone) {
        border-radius: var(--border-radius-l);
        margin-left: var(--gap);
        padding-bottom: 50%;
        width: calc((100% / 3) - var(--gap));

        &:nth-child(3n - 2) { margin-left: 0; }

        .media-holder {
            height: rem(712);
            width: rem(538);
        }
    }
}
