.block-title {
    --font-size-h1: var(--font-size-sans-black-xxxxlarge);
    --color: var(--gray);
    --bg: var(--red);
    --border: var(--red);
    --padding-v: var(--padding-m);
    --padding-h: var(--padding-m);

    @media (max-width: $smartphone) {
        --padding-v: var(--padding-xs);
        --padding-h: var(--padding-xs);
    }
}

.block-title {
    color: var(--color);
    padding: var(--padding-v) var(--padding-h) 0;
    
    > div {
        @include box();
        background: var(--bg);
        padding: var(--padding-xxxl) 0;
        text-align: center;
    }

    h1 {
        font-size: var(--font-size-h1);
        margin: 0;

        @include font-sans-black();
    }

    @media (max-width: $smartphone) {
        padding: var(--padding-xs);
    }
}
