.block-contact {
    --font-size-h1: var(--font-size-sans-black-xxxxlarge);
    --font-size-rrss: var(--font-size-sans-black-xlarge);
    --font-size: var(--font-size-sans-medium-mid);
    --color: var(--gray);
    --bg: var(--red);
    --border: var(--red);
    --padding-v: var(--padding-s);
    --padding-h: var(--padding-m);
    
    @media (max-width: $smartphone) {
        --padding-v: var(--padding-m);
        --padding-h: var(--padding-xs);
        --font-size: var(--font-size-sans2-base);
    }
}

.block-contact {
    color: var(--color);
    padding: var(--padding-v) var(--padding-h);

    &__content {
        display: grid;
        font-size: var(--font-size);
        grid-template-areas:
          "item"
          "item"
          "item"
          "item"
          "item"
          "item";
        grid-template-columns: 1fr;
        grid-gap: var(--margin-xs);
        grid-template-rows: auto;
    }

    @media (min-width: $smartphone) {
        &__content {
            grid-template-areas:
            "item item"
            "item item"
            "item item";
            grid-template-columns: 1fr 1fr;
        }
    }

    @media (min-width: $tabletPortrait) {
        &__content {
            grid-template-areas:
            "item item item"
            "item item item";
            grid-template-columns: 1fr 1fr 1fr;
        }
    }
}
