@mixin box() {
    border: rem(7) solid var(--border);
    border-radius: var(--border-radius-m);

    @media (max-width: $smartphone) {
        border-width: rem(2);
        border-radius: var(--border-radius-s);
    }
}

.box {
    --bg: transparent;
    --color: var(--red);

    background: var(--bg);
    color: var(--color);
    padding-bottom: 60%;
    position: relative;
    text-align: center;
    text-decoration: none;

    @include box();

    &:hover {
        --bg: var(--red);
        --color: var(--gray);
    }

    > * {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        pointer-events: none;
        position: absolute;
        width: 100%;

        @include center();
    }

    .text {
        font-size: var(--font-size-rrss);
        margin: 0;
        margin-bottom: var(--padding-s);

        @include font-sans-black();
    }

    .support {
        @include font-sans-medium();

        font-weight: 400;
        margin-top: var(--margin-xs);
        max-width: rem(340);
        width: 100%;
    }
    
    @media (max-width: $smartphone) {
        .support {
            @include font-sans2-regular();
        }
    }
}
