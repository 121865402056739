@mixin box() {
    border: rem(7) solid var(--border);
    border-radius: var(--border-radius-m);

    @media (max-width: $smartphone) {
        border-width: rem(3);
        border-radius: var(--border-radius-xs);
    }
}

.block-game {
    --font-size-h1: var(--font-size-sans-black-xxxxlarge);
    --font-size-h2: var(--font-size-serif-extrabold-large);
    --padding-h: var(--padding-xs);
    --margin-v: var(--padding-xxxl);
    --color: var(--primary);
    --border: var(--primary);
    --line-position: var(--padding-s);
    
    .palette-primary & {
        --primary: var(--red);
        --secondary: var(--deepBlue);
    }
    
    .palette-black & {
        --primary: var(--deepBlue);
        --secondary: var(--red);
    }
    
    @media (max-width: $smartphone) {
        --font-size-h1: var(--font-size-sans-black-xxxlarge);
        --margin-v: var(--padding-m);
    }

    @media (min-width: $smartphone) {
        --padding-h: 10%;
        --line-position: var(--padding-l);
    }

    @media (min-width: $xlargeScreen) {
        --padding-h: 15%;
    }
}

.block-game {
    color: var(--color);
    margin: var(--margin-v) auto;
    padding: var(--padding-xs) var(--padding-h) var(--padding-l);
    position: relative;
    text-align: center;

    .lines {
        align-items: flex-start;
        bottom: 0;
        content: '';
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        padding: var(--padding-xs) 0 var(--padding-l);
        position: absolute;
        top: 0;
        width: 2%;

        span {
            background: var(--primary);
            border-radius: 10px;
            display: block;
            height: 4px;
            width: 100%;
        }
    }

    .lines.left { left: var(--line-position); }
    .lines.right { right: var(--line-position); }

    h1,
    h2 {
        margin: 0;
        margin-bottom: var(--margin-xs);
        padding: var(--padding-xs);

        @include box();
    }

    h1 {
        font-size: var(--font-size-h1);

        @include font-sans-black();
    }

    h2 {
        color: var(--green);
        font-size: var(--font-size-h2);
        text-transform: uppercase;

        @include font-serif-extrabold(1);
    }

    @media (max-width: $tabletPortrait) {
        h1,
        h2 {
            margin-bottom: var(--margin-xxs);
        }
    }

    @media (max-width: $smartphone) {
        padding: var(--padding-xs) var(--padding-h);

        .lines { display: none; }
    }
}