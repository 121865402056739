#Header {
    --width: 100%;
    --height: var(--header-height);
    --color: var(--primary);
    // --bg: var(--bgcolor);
    --padding-v: 0;
    --padding-h: var(--padding-s);
    --font-size: var(--font-size-base);

    @media (max-width: $smartphone) {
        --padding-h: var(--padding-xs);
    }
}

#Header {
    @include z-index($z-index-header);
    height: var(--full-header);
    position: fixed;
    transform: translate3d(0, var(--y-header), #{$z-index-header + px});
    right: 0;
    top: 0;
    width: var(--width);
    
    a { text-decoration: none; }
    
    .wrapper {
        align-items: center;
        background-color: var(--bg);
        color: var(--color);
        display: flex;
        height: var(--height);
        justify-content: space-between;
        width: var(--width);
        padding: var(--padding-v) var(--padding-h);
    }

    nav {
        align-items: center;
        display: flex;
        justify-content: flex-end;
        flex-grow: 1;
        font-size: var(--font-size-sans-medium-base);
        position: relative;
        text-transform: uppercase;

        @include font-sans-medium();

        .link {
            color: var(--color);
            margin: 0 rem(65 / 2);

            &:last-child { margin-right: 0; }
        }
    }

    .toggle-school,
    .toggle-menu {
        @include basic-a();
        @include font-sans-black();

        color: var(--color);
        font-size: var(--font-size-sans-medium-small);
        height: var(--header-height);
        text-transform: uppercase;
    }

    .toggle-menu {
        background-color: transparent;
        border: 0;
        padding: 0;
    }

    // .toggle-school {
    //     align-items: center;
    //     display: flex;

    //     .toggle {
    //         margin-right: var(--margin-xxs);
    //     }

    //     .palette-black &--offline { display: none; }
    //     .palette-primary &--online { display: none; }
    // }

    .logo {
        color: var(--red);
        font-size: var(--font-size-sans-bold-large);

        @include font-sans-black();
    }

    .camera-btn {
        background: transparent;
        height: rem(32);
        left: var(--padding-h);
        padding: 0;
        position: relative;
        width: rem(73);

        svg {
            height: 100%;
            width: 100%;
        }
    }

    @media (max-width: $smartphone) {
        .logo {
            display: none;
        }

        nav {
            display: none;
        }
    }

    @media (min-width: $smartphone) {
        --padding-h: var(--padding-l);

        .wrapper {
            justify-content: center;
        }

        .toggle-menu { display: none; }

        // .toggle-school { display: none; }

        .camera-btn {
            height: rem(55);
            width: rem(121);
        }
    }
}
