.block-channels {
    --font-size-h2: var(--font-size-sans-black-xxxxlarge);
    --font-size: var(--font-size-sans2-xxlarge);
    --margin-v: var(--margin-s);
    --margin-h: var(--padding-m);
    --padding-v: var(--padding-m);
    --padding-h: var(--padding-m);
    --bg: var(--green);
    --title-color: var(--yellow);
    --text-color: var(--black);
    --title-width: 70%;

    @media (max-width: $smartphone) {
        --font-size-h2: var(--font-size-sans-black-xxxlarge);

        --margin-v: var(--margin-xs);
        --margin-h: var(--padding-xs);
        --padding-v: var(--padding-s);
        --padding-h: var(--padding-s);
    }
}

.block-channels {
    color: var(--text-color);
    padding: var(--margin-v) var(--margin-h);
    position: relative;

    &__image {
        position: absolute;

        img { width: 100%; }

        &--0 {
            left: 1%;
            top: 54%;
            width: rem(338);
            z-index: 0;
        }

        &--1 {
            top: 66%;
            left: 42%;
            width: rem(182);
            z-index: 3;
        }

        &--2 {
            bottom: 6%;
            right: 1%;
            width: rem(347);
            z-index: 1;
        }

        &--3 {
            bottom: 0%;
            right: 38%;
            width: rem(211);
            z-index: 2;
        }
    }

    &__wrapper {
        background: var(--bg);
        border-radius: var(--border-radius-s);
        padding: var(--padding-v) var(--padding-h) 105%;
        position: relative;
    }

    h2 {
        color: var(--title-color);
        font-size: var(--font-size-h2);
        margin: 0;

        @include font-sans-black();
    }

    .text {
        font-size: var(--font-size);
        margin: var(--margin-s) 0 0;

        @include font-sans2-regular();
    }

    @media (min-width: $smartphone) {
        h2 {
            width: var(--title-width);
        }

        .text {
            width: 45%;
        }

        &__wrapper {
            border-radius: var(--border-radius-l);
            padding: var(--padding-v) var(--padding-h) rem(600);
        }

        &__image {
            position: absolute;

            img { width: 100%; }

            &--0 {
                height: rem(431);
                left: 2%;
                top: 49%;
                width: rem(1026);
                z-index: 0;
            }

            &--1 {
                top: 40%;
                height: rem(344);
                left: 48%;
                width: rem(555);
                z-index: 3;
            }

            &--2 {
                bottom: -6%;
                height: rem(703);
                right: 4%;
                width: rem(1056);
                z-index: 1;
            }

            &--3 {
                bottom: 19%;
                height: rem(448);
                right: 3%;
                width: rem(641);
                z-index: 2;
            }
        }
    }
}
