@keyframes loading-tembleque {
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
}

.video-stories {
    background: var(--black);
    border: rem(7) solid var(--green);
    height: rem(1100);
    position: fixed;
    right: 0;
    top: 0;
    transform: translate3d(100%, 0, 0);
    width: rem(650);

    @include z-index($z-index-windows);

    &[aria-expanded='false'] {
        visibility: hidden;
        pointer-events: none;
    }

    .loader {
        animation: loading-tembleque .8s infinite;
        background: var(--yellow);
        border: 0;
        border-radius: 50%;
        display: block;
        height: rem(50);
        pointer-events: none;
        position: absolute;
        width: rem(50);
        z-index: 0;

        @include center();
    }

    .camera-btn {
        background: transparent;
        height: rem(32);
        padding: 0;
        position: absolute;
        left: calc(100% + #{rem(7)});
        transform: translate3d(-100%, -50%, 0);
        top: calc(var(--header-height) / 2 - #{rem(7)});
        width: rem(73);
        z-index: 2;

        svg {
            height: 100%;
            width: 100%;
        }
    }

    .swiper-container {
        height: 100%;
        width: 100%;
    }

    video {
        height: 100%;
        object-fit: cover;
        position: relative;
        width: 100%;
        z-index: 1;
    }

    .video-files { display: none; }

    .controls {
        align-items: center;
        bottom: rem(25);
        display: flex;
        justify-content: center;
        position: absolute;
        width: 100%;
        z-index: 2;

        button {
            background: transparent;
            padding: 0;

            svg {
                height: 100%;
                width: 100%;
            }
        }

        .btn-prev,
        .btn-next {
            height: rem(56);
            margin: 0;
            position: relative;
            width: rem(56);

            &::after { display: none; }
        }

        .btn-prev { margin-right: var(--margin-s); }
        .btn-next { margin-left: var(--margin-s); }

        .btn-ok {
            height: rem(73);
            width: rem(73);
        }
    }

    @media (max-width: $tabletPortrait) {
        border-width: 5px;
        height: 550px;
        width: 325px;
    }

    @media (min-width: $tabletPortrait) {
        max-height: 85vh;

        .camera-btn {
            height: rem(55);
            width: rem(121);
        }
    }
}