#Sidemenu {
    // --font-size-big: var(--font-size-mid);
    // --font-size: var(--font-size-mid);

    &[aria-expanded='false'] {
        visibility: hidden;
        pointer-events: none;
    }

    .palette-black & {
        --bg: var(--gray);
        --color: var(--black);
        --link-color: var(--red);
    }

    .palette-primary & {
        --bg: var(--black);
        --color: var(--gray);
        --link-color: var(--yellow);
    }
}

#Sidemenu {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;

    color: var(--color);
    background-color: var(--bg);

    a { @include basic-a(); }

    .toggle-menu {
        @include basic-a();
        @include font-sans-black();

        background-color: transparent;
        border: 0;
        color: var(--color);
        font-size: var(--font-size-sans-medium-small);
        height: var(--header-height);
        padding: 0;
        text-transform: uppercase;
    }

    @include z-index($z-index-windows);
}

#Sidemenu > .content {
    height: 100%;
    left: 0;
    padding: 0 var(--padding-xs) var(--padding-m);
    position: absolute;
    top: 0;
    width: 100%;

    overflow-y: scroll;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;

    @include z-index(1);
}

#Sidemenu nav {
    padding: var(--padding-l) 0 var(--margin-xxl);

    a {
        @include font-sans-black();

        color: var(--color);
        display: block;
        font-size: var(--font-size-sans-black-l);
        text-decoration: none;

        &:not(:last-child) {
            margin-bottom: var(--padding-s);
        }
    }
}

#Sidemenu .sidemenu__contact-links {
    a {
        color: var(--link-color);
        display: block;

        &:not(:last-child) {
            margin-bottom: var(--margin-xxs);
        }

        &:not(:first-child) {
            font-size: var(--font-size-sans2-base);

            @include font-sans2-regular();
        }

        &:first-child {
            font-size: var(--font-size-sans-black-base-sm);

            @include font-sans-black();
        }

        &.telf {
            position: relative;

            svg {
                height: rem(32);
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                width: rem(32);
            }
        }
    }

    @media (max-width: $smartphoneMid) {
        a:first-child { font-size: var(--font-size-sans-black-base-sm); }
    }

    div:not(:last-child) { margin-bottom: var(--padding-xs); }
}
