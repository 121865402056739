@use "sass:math";

#Footer {
    --padding-v: var(--padding-m);
    --padding-h: var(--padding-m);
    --font-size-logo: var(--font-size-sans-black-xxxlarge);
    --font-size: var(--font-size-sans-black-base);

    @media (max-width: $smartphone) {
        --padding-v: var(--padding-xs);
        --padding-h: var(--padding-xs);
    }
}

#Footer {
    @include font-sans-black();
    color: var(--red);
    font-size: var(--font-size);
    
    padding: var(--padding-v) var(--padding-h);
    
    a {
        @include basic-a();
        color: var(--red);
        display: block;
    }

    .logo {
        font-size: var(--font-size-logo);
    }
    
    .top {
        margin-bottom: var(--padding-xl);
    }
    
    .center {
        margin-bottom: var(--padding-m);

        > div {
            .link {
                max-width: rem(360);   
            }
              
            .link:not(:last-child) {    
                margin-bottom: var(--padding-xxs);
            }
        }
    }


    .bottom,
    .center {
        > div:last-child {
            @include font-sans-medium();
        }
    } 
      
    @media (max-width: $smartphone) {
        .logo {
            margin-bottom: var(--padding-xs);
        }

        .center {
            > div {
                .link:not(:last-child) {    
                    margin-bottom: var(--padding-xs);
                }
            }

            > div:not(:last-child) {
                margin-bottom: var(--padding-s);
            }
        }
        
        .bottom {
            > div:last-child {
                margin-top: var(--padding-s);
            }
        }
    }

    @media (min-width: $smartphone) {
        .top {
            margin-bottom: var(--padding-xxxl);
            display: flex;
            align-items: flex-end;
            justify-content: space-between;

            > * {
                width: 48%;
            }
        }
        
        .center {
            margin-bottom: var(--padding-xs);

            > div {
                .link:not(:last-child) {    
                    min-height: rem(85);
                    margin-bottom: var(--padding-xxs);
                }
            }
        }

        .bottom,
        .center {
            display: flex;
            justify-content: space-between;

            > * {
                flex-basis: 22%;
            }
        } 
        
        .bottom {
            > div {
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
            }
        }
    }
}
