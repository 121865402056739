.widget-form {
    --font-size: var(--font-size-sans-black-xl);
    --color: var(--gray);
    --bg: var(--deepBlue);
    --border: var(--deepBlue);
}

.widget-form {
    color: var(--color);
    padding: var(--padding-xs) var(--padding-l);

    > div {
        @include box();
        background: var(--bg);
        padding: var(--padding-xxxl) var(--padding-xl);
        text-align: center;
    }


    @media (max-width: $smartphone) {
        padding: var(--padding-xs);

        > div {
            padding: var(--padding-s);
        }
    }

    @media (min-width: $smartphone) {
        .default-form {
            --font-size: var(--font-size-sans2-xxlarge);
            --font-size-submit: var(--font-size-sans-black-xlarge);
        }

        .default-form__textfield {
            margin: 0 0 var(--margin-s);
        }

        .default-form__checkbox {
            --font-size: var(--font-size-sans2-xxlarge);
        }
    }
}
