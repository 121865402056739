.block-courses {
    --padding-v: var(--padding-m);
    --padding-h: var(--padding-m);

    @media (max-width: $smartphone) {
        --padding-v: var(--padding-xs);
        --padding-h: var(--padding-xs);
    }
}

.block-courses {
    padding: var(--padding-v) var(--padding-h);

    &__wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
}