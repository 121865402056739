.course-page {
    --margin-v: 0;
    --padding-v: var(--padding-xxl);
    --padding-h: var(--padding-l);
    --max-width: 100%;

    @media (max-width: $smartphone) {
        --padding-v: var(--padding-xs);
        --padding-h: var(--padding-xs);
    }
}

.course-page {
    padding: var(--padding-v) var(--padding-h) var(--padding-h);

    &__content {
        align-items: center;
        display: flex;
        justify-content: center;
        margin: 0 auto;
        max-width: var(--max-width);
        padding-top: var(--header-height);
    }
}

.course-page + .block-contact {
    margin-top: 0;
    padding-top: 0;

    .block-contact__header {
        display: none;
    }
}
