.course {
    --font-size-h3: var(--font-size-sans-black-xxxxxlarge);
    --font-size-number: var(--font-size-sans-medium-large);
    --font-size-unit: var(--font-size-sans2-xlarge);
    --font-size: var(--font-size-sans2-mid);
    --font-size-price-text: var(--font-size-sans2-base);
    --padding-v: var(--padding-m);
    --padding-h: var(--padding-s);
    --color: var(--white);

    &.--blue {
        --bg: var(--deepBlue);
    }

    &.--green {
        --bg: var(--green);
    }

    &.--red {
        --bg: var(--red);
    }

    @media (max-width: $tabletPortrait) {
        --font-size: var(--font-size-sans2-xxlarge);
        --font-size-number: var(--font-size-sans-medium-xxlarge);
        --font-size-unit: var(--font-size);
    }

    @media (max-width: $smartphone) {
        --padding-v: var(--padding-xs);
        --padding-h: var(--padding-xs);
        --font-size-h3: var(--font-size-sans-black-l);
    }

    @media (max-width: $smartphoneMid) {
        --font-size-number: 2rem;
        --font-size-unit: 1.25rem;
        --font-size-price-text: 1rem;
    }

    @media (max-width: $smartphoneSmall) {
        --font-size-price-text: .9rem;
    }
}

.course {
    background: var(--bg);
    border-radius: var(--border-radius-s);
    color: var(--color);
    display: block;
    font-size: var(--font-size);
    overflow: hidden;
    text-decoration: none;

    @include font-sans2-regular();

    &__back {
        height: 100%;
        padding: var(--padding-v) var(--padding-h);
        position: relative;
    }

    &__back {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    &__header {
        flex: 1 0 100%;
        position: relative;
        display: flex;
        margin-bottom: var(--padding-l);

        > .content {
            flex: 1;
        }

        > .image {
            flex: 0 0 var(--font-size-h3);
            height: rem(92);
            width: rem(85);
        }

        .title {
            font-size: var(--font-size-h3);
            margin: 0;
            max-width: 85%;

            @include font-sans-black();
        }

        p { margin: var(--margin-xxs) 0 0; }
    }

    &__info {
        flex: 1 0 #{(320px / 16px) * 1rem};

        align-items: flex-start;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @media (max-width: $tabletPortrait) {
            flex: 1 0 100%;
        }
    }

    &__description {
        margin: 0 0 var(--padding-l) 0;
    }

    &__footer {
        .price {
            .qty {
                align-items: flex-end;
                font-size: var(--font-size-number);
                display: flex;
                justify-content: center;

                @include font-sans-medium();
            }

            .unit {
                font-size: var(--font-size-unit);

                @include font-sans2-regular();
            }

            .unit.prev {
                line-height: var(--font-size-number);
                margin-right: var(--margin-xxs);
            }

            .number { margin: 0 var(--margin-xxxs); }

            .text {
                font-size: var(--font-size-price-text);
                margin: 0;
            }
        }
    }

    &__form {
        flex: 1 0 #{(320px / 16px) * 1rem};
        padding-left: var(--padding-xxxl);

        @media (max-width: $tabletPortrait) {
            flex: 1 0 100%;
            padding-left: 0;
        }
    }

    .default-form {
        display: none;
        --font-size: var(--font-size);
    }

    a {
        @include basic-a();
        color: currentColor;
        text-decoration: underline;
    }

    @media (max-width: $smartphone) {
        &__footer {
            width: 100%;

            .text {
                border-bottom: 1px solid currentColor;
            }

            .price {
                align-items: center;
                display: flex;
                flex-direction: row-reverse;
                justify-content: space-between;
                // max-width: 320px;
            }
        }

        &__header {
            flex-direction: column;
            align-items: center;

            > .image {
                width: 72%;
                height: auto;
            }
        }
    }

    @media (max-width: $tabletPortrait) {
        &__back {
            padding: calc(var(--padding-v) * 2) var(--padding-h);
        }

        &__footer {
            margin-bottom: var(--padding-l);

            .text { font-size: var(--font-size-sans2-xxlarge); }
        }
    }

    @media (min-width: $smartphone) {
        border-radius: var(--border-radius-l);
        position: relative;

        &__footer {
            align-items: flex-end;
            display: flex;
            justify-content: space-between;
            margin-top: var(--padding-s);
            margin-bottom: var(--padding-l);
            width: 100%;

            .price {
                // border-bottom: 1px solid currentColor;

                &:first-child .qty { justify-content: flex-start; }
                &:last-child .qty { justify-content: flex-end; }
            }
        }
    }

    @media (min-width: $tabletPortrait) {
        &__header {
            .image {
                height: rem(116);
                width: rem(107);
            }
        }

        &__description {
            margin: 0 0 var(--padding-l) 0;
        }
    }
}
