.block-perfect-match {
    --font-size: var(--font-size-sans2-xxlarge);
    --margin-v: var(--padding-m);
    --margin-h: var(--padding-m);
    --padding-v: var(--padding-m);
    --padding-h: var(--padding-m);
    --bg: var(--deepBlue);
    --text-color: var(--white);

    @media (max-width: $smartphone) {
        --font-size-h2: var(--font-size-sans-black-xxxlarge);

        --margin-v: var(--margin-xs);
        --margin-h: var(--padding-xs);
        --padding-v: var(--padding-s);
        --padding-h: var(--padding-s);
    }
}

.block-perfect-match {
    color: var(--text-color);
    overflow: hidden;
    padding: var(--margin-v) var(--margin-h);
    position: relative;

    &__wrapper {
        background: var(--bg);
        border-radius: var(--border-radius-s);
        padding: var(--padding-v) var(--padding-h);
        position: relative;
    }

    h2 { margin: 0; }

    .icon-perfect-match {
        display: block;
        height: 100%;
        width: 100%;

        svg {
            height: 100%;
            width: 100%;
        }
    }

    .text {
        font-size: var(--font-size);
        margin: var(--margin-xxs) 0 0;

        @include font-sans2-regular();
    }

    &__image {
        opacity: 0;
        position: absolute;
        transform: scale3d(4, 4, 1);

        .media-holder { height: 100%; }

        &--1 {
            z-index: 1;

            .block-perfect-match--offline & {
                bottom: 38%;
                height: rem(176);
                left: 3%;
                width: rem(160);

                .media-holder { transform: rotate(-35deg); }
            }

            .block-perfect-match--online & {
                height: rem(191);
                bottom: 29%;
                left: 3%;
                width: rem(351);
            }
        }

        &--2 {
            z-index: 2;

            .block-perfect-match--offline & {
                height: rem(103);
                bottom: 30%;
                left: 27%;
                width: rem(229);
            }

            .block-perfect-match--online & {
                bottom: 25%;
                height: rem(85);
                left: 10%;
                width: rem(187);
            }
        }

        &--3 {
            z-index: 1;

            .block-perfect-match--offline & {
                bottom: -3%;
                height: rem(310);
                left: 0%;
                width: rem(363);
            }

            .block-perfect-match--online & {
                bottom: 0%;
                height: rem(256);
                left: -1%;
                width: rem(353);
            }
        }
    }

    @media (max-width: $smartphone) {
        &__wrapper {
            padding-bottom: 110%;
        }

        &__image--3 {
            .block-perfect-match--offline & {
                .media-holder { transform: rotate(45deg); }
            }
        }
    }

    @media (max-width: $smartphoneMid) {
        &__wrapper {
            padding-bottom: 120%;
        }

        .media-holder {
            transform: scale3d(.8, .8, 1);
        }
    }

    @media (min-width: $smartphone) {
        .text {
            margin: var(--margin-s) 0 0;
            width: 40%;
        }

        .icon-perfect-match {
            height: rem(897);
            width: rem(1764);
        }

        &__wrapper {
            border-radius: var(--border-radius-l);
        }

        &__image {
            opacity: 0;
            transform: scale3d(4, 4, 1);

            &--1 {
                .block-perfect-match--offline & {
                    bottom: 10%;
                    height: rem(498);
                    left: 15%;
                    width: rem(469);
                }

                .block-perfect-match--online & {
                    bottom: 16%;
                    height: rem(482);
                    left: 38%;
                    width: rem(885);
                }
            }

            &--2 {
                .block-perfect-match--offline & {
                    bottom: 15%;
                    height: rem(249);
                    left: calc(50% - #{rem(549) / 2});
                    width: rem(549);
                }

                .block-perfect-match--online & {
                    bottom: 14%;
                    height: rem(154);
                    left: 48%;
                    width: rem(398);
                }
            }

            &--3 {
                .block-perfect-match--offline & {
                    bottom: -1%;
                    height: rem(623);
                    left: auto;
                    right: -1%;
                    width: rem(730);
                }

                .block-perfect-match--online & {
                    bottom: 0;
                    height: rem(506);
                    left: auto;
                    right: 0;
                    width: rem(696);
                }
            }
        }
    }
}
